<template>
    <div class="no_containts">
        <template v-for="(item, keyItem) in schedule">
            <div :key="'SHIFT_SCHEDULE' + keyItem">
                <div class="gh-row-content">
                    <template v-if="$root._route.params.view || view">
                        <label class="gh_text_field-label T15" style="margin-right: 10px !important; width: 150px; min-width: 150px; text-align: right;">{{$t('week_from')}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px !important; width: 25px">{{item.week_from}}</label>
                        <label class="gh_text_field-label T15" style="margin-right: 10px !important;">{{$t('to').toLowerCase()}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px !important; width: 25px">{{item.week_to}}</label>
                        <label class="gh_text_field-label T15" style="margin-right: 10px !important; min-width: 55px;">
                            {{$route.name === 'config_location_location_modify' || $route.name === 'config_location_location' || $route.name === 'config_location_location_view' || $route.name === 'config_equipment_equipment_modify' || $route.name === 'config_equipment_equipment' || $route.name === 'config_equipment_equipment_view' ? $t('availability_from') : $t('entrance')}}
                        </label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px !important; width: 50px;">{{item.entrance_of}}</label>
                        <template v-if="$route.name === 'config_location_location_view' || $route.name === 'config_location_location' || $route.name === 'config_location_location_modify'">
                            <label class="gh_text_field-label T15" style="margin-right: 10px !important; width: 15px;">{{$t('to').toLowerCase()}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px !important; width: 50px;">{{item.entrance_to}}</label>
                        </template>
                        <template v-if="$route.name !== 'config_location_location_modify' && $route.name !== 'config_location_location' && $route.name !== 'config_location_location_view' && $route.name !== 'config_equipment_equipment_modify' && $route.name !== 'config_equipment_equipment' && $route.name !== 'config_equipment_equipment_view'">
                            <label class="gh_text_field-label T15" style="margin-right: 10px !important; min-width: 65px;">{{$t('work_hour')}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px !important; width: 50px">{{item.hours}}</label>
                            <label class="gh_text_field-label T15" style="margin-right: 10px !important; min-width: 55px; margin-left: 10px; white-space: nowrap">{{$t('exit_hour')}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 90px; min-width: 90px; white-space: nowrap" :key="'EXIT_HOUR_' + keyItem + refreshExitHour">{{calcHourExit(item.entrance_of, item.hours)}}</label>
                        </template>
                    </template>
                    <template v-else>
                        <div>
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('week_from') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_schedule_' + keyItem + '_week_from',
                                    name: 'data[schedule][' + keyItem + '][week_from]',
                                    style: 'width: 25px',
                                    value: item.week_from,
                                    type: 'int',
                                    limitNumMax: 52
                                }"
                                :ref="'RefWeekFrom' + keyItem"
                                @focusout="persistance(keyItem)"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('to').toLowerCase(),
                                    style: 'margin-left: 10px;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: false,
                                    id: 'data_schedule_' + keyItem + '_week_to',
                                    name: 'data[schedule][' + keyItem + '][week_to]',
                                    style: 'width: 25px',
                                    value: item.week_to,
                                    type: 'int',
                                    limitNumMax: 52
                                }"
                                :ref="'RefWeekTo' + keyItem"
                                @focusout="persistance(keyItem)"
                            />
                            <GhInputHour
                                :datalabel="{
                                    style: 'margin-top: 2px; margin-left: 10px;' + ($root._route.params.from === 'location' || $root._route.params.from === 'equipment' ? 'min-width: 120px;' : ''),
                                    text: $root._route.params.from === 'location' || $root._route.params.from === 'equipment' ? $t('availability_from') : $t('entrance')
                                }"
                                :datainput="{
                                    actualHour: false,
                                    id: 'data_schedule_' + keyItem + '_entrance_of',
                                    name: 'data[schedule][' + keyItem + '][entrance_of]',
                                    required: true,
                                    label_required: $t('entrance') + '*',
                                    hour: item.entrance_of !== null && item.entrance_of !== '' ? item.entrance_of.split(':')[0] : '',
                                    minute: item.entrance_of !== null && item.entrance_of !== '' ? item.entrance_of.split(':')[1] : '',
                                    style: 'max-width: 40px;'
                                }"
                                :ref="'RefEntranceOf' + keyItem"
                                @focusout="persistance(keyItem)"
                            />
                            <template v-if="$root._route.params.from === 'location'">
                                <GhInputHour
                                    :datalabel="{
                                        style: 'margin-top: 2px; margin-left: 10px;',
                                        text: $t('to').toLowerCase()
                                    }"
                                    :datainput="{
                                        actualHour: false,
                                        id: 'data_schedule_' + keyItem + '_entrance_to',
                                        name: 'data[schedule][' + keyItem + '][entrance_to]',
                                        required: true,
                                        label_required: $t('entrance') + ' ' + $t('to') + '*',
                                        hour: item.entrance_to !== null && item.entrance_to !== '' ? item.entrance_to.split(':')[0] : '',
                                        minute: item.entrance_to !== null && item.entrance_to !== '' ? item.entrance_to.split(':')[1] : '',
                                        style: 'max-width: 40px;'
                                    }"
                                    :ref="'RefEntranceTo' + keyItem"
                                    @focusout="persistance(keyItem)"
                                />
                            </template>
                        </div>
                        <template v-if="$root._route.params.from !== 'location' && $root._route.params.from !== 'equipment'">
                            <GhInputHour
                                :datalabel="{
                                    style: 'margin-top: 2px; margin-left: 25px; min-width: 85px;',
                                    text: $t('work_hour')
                                }"
                                :datainput="{
                                    actualHour: false,
                                    id: 'data_schedule_' + keyItem + '_work_hour',
                                    name: 'data[schedule][' + keyItem + '][hours]',
                                    required: true,
                                    label_required: $t('work_hour') + '*',
                                    hour: item.hours !== null && item.hours !== '' ? item.hours.split(':')[0] : '',
                                    minute: item.hours !== null && item.hours !== '' ? item.hours.split(':')[1] : '',
                                    style: 'max-width: 40px;'
                                }"
                                :ref="'RefWorkHour' + keyItem"
                                @focusout="persistance(keyItem)"
                            />
                            <label class="gh_text_field-label T15" style="margin-right: 10px; min-width: 55px; margin-left: 10px; white-space: nowrap">{{$t('exit_hour')}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 90px; min-width: 90px; white-space: nowrap" :key="'EXIT_HOUR_' + keyItem + refreshExitHour">{{calcHourExit(item.entrance_of, item.hours)}}</label>
                        </template>
                    </template>
                    <div :key="'DAYS_' + keyItem + refreshSchedule">
                        <div :style="fireFox ? '' : 'display: ruby;'" :class="fireFox ? 'grid-by-width' : ''">
                            <template v-for="(d, keyDays) in days">
                                <div :key="keyDays" style="display: flex;">
                                    <GhChecks style="margin-left: 20px;"
                                        :datainput="{
                                            id: 'day_' + keyItem + '_' + keyDays,
                                            value: d,
                                            type: 'checkbox',
                                            name: 'data[schedule][' + keyItem + '][days][]',
                                            selected: item.days.includes(d),
                                            disabled: $root._route.params.view || view,
                                            required: true,
                                            label_required: $t('work_days') + '*'
                                        }"
                                        :datalabel="{
                                            id: 'label_day_' + keyItem + '_' + keyDays,
                                            text: ''
                                        }"
                                        :ref="'RefDay' + keyItem + keyDays"
                                        @click="persistance(keyItem)"
                                    />
                                    <label class="T15" style="margin-top: 2px; margin-left: 10px; min-width: 80px;">{{$t(d)}}</label>
                                </div>
                            </template>
                        </div>
                    </div>
                    <GhAction style="margin-left: 20px; min-width: 100px" v-if="schedule.length !== 1 && !$root._route.params.view && !view"
                        :dataction="{
                            id: 'remove_schedule',
                            text: $t('delete'),
                            icon: require('@/assets/gh_new_dessign/trash.svg')
                        }"
                        @click="deleteScheduleLine(keyItem)"
                    />
                </div>
            </div>
        </template>
        <div class="gh-row-content" style="margin-left: 160px;" v-if="!$root._route.params.view && !view">
            <GhAction
                :dataction="{
                    id: 'add_new_schedule',
                    text: $t('add_new_register'),
                    icon: require('@/assets/gh_new_dessign/add_plus.svg')
                }"
                @click="newScheduleLine"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhInputHour from "fe-gh-input-hour-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'template_schedule',
        props: ['scheduleData','view'],
        components: {
            GhInputTextField,
            GhInputHour,
            GhChecks,
            GhAction
        },
        data(){
            return {
                emptySchedule: {
                    week_from: '',
                    week_to: '',
                    entrance_of: '',
                    entrance_to: '',
                    hours: '',
                    days: []
                },
                schedule: [],
                days: [
                    'monday',
                    'tuesday',
                    'wednesday',
                    'thursday',
                    'friday',
                    'saturday',
                    'sunday'
                ],
                refreshSchedule: 0,
                refreshExitHour: 0,
                fireFox: this.detectFirefox()
            }
        },
        mounted() {
            this.schedule = this.$props.scheduleData;
        },
        methods: {
            persistance(ref){
                if(ref !== undefined) {
                    let week_from = '';
                    let week_to = '';
                    let entrance_of = '';
                    let entrance_to = '';
                    let hour = '';
                    let days = [];

                    if (this.$refs['RefWeekFrom' + ref][0].datainput !== undefined) {
                        week_from = this.$refs['RefWeekFrom' + ref][0].datainput.value;
                    }

                    if (this.$refs['RefWeekTo' + ref][0].datainput !== undefined) {
                        week_to = this.$refs['RefWeekTo' + ref][0].datainput.value;
                    }

                    if (this.$refs['RefEntranceOf' + ref][0].datainput !== undefined) {
                        entrance_of = document.getElementById('input_time_data_schedule_' + ref + '_entrance_of_hour').value + ':' + document.getElementById('input_time_data_schedule_' + ref + '_entrance_of_minute').value;
                    }

                    if (this.$refs['RefEntranceTo' + ref][0].datainput !== undefined) {
                        entrance_to = document.getElementById('input_time_data_schedule_' + ref + '_entrance_to_hour').value + ':' + document.getElementById('input_time_data_schedule_' + ref + '_entrance_to_minute').value;
                    }

                    if(this.$root._route.params.from !== 'location' && this.$root._route.params.from !== 'equipment') {
                        if (this.$refs['RefWorkHour' + ref][0].datainput !== undefined) {
                            hour = document.getElementById('input_time_data_schedule_' + ref + '_work_hour_hour').value + ':' + document.getElementById('input_time_data_schedule_' + ref + '_work_hour_minute').value;
                        }
                    }

                    if (this.$refs['RefDay' + ref + '0'][0].selected) {
                        days.push(this.$refs['RefDay' + ref + '0'][0].datainput.value);
                    }

                    if (this.$refs['RefDay' + ref + '1'][0].selected) {
                        days.push(this.$refs['RefDay' + ref + '1'][0].datainput.value);
                    }

                    if (this.$refs['RefDay' + ref + '2'][0].selected) {
                        days.push(this.$refs['RefDay' + ref + '2'][0].datainput.value);
                    }

                    if (this.$refs['RefDay' + ref + '3'][0].selected) {
                        days.push(this.$refs['RefDay' + ref + '3'][0].datainput.value);
                    }

                    if (this.$refs['RefDay' + ref + '4'][0].selected) {
                        days.push(this.$refs['RefDay' + ref + '4'][0].datainput.value);
                    }

                    if (this.$refs['RefDay' + ref + '5'][0].selected) {
                        days.push(this.$refs['RefDay' + ref + '5'][0].datainput.value);
                    }

                    if (this.$refs['RefDay' + ref + '6'][0].selected) {
                        days.push(this.$refs['RefDay' + ref + '6'][0].datainput.value);
                    }

                    this.schedule[ref] = {
                        week_from: week_from,
                        week_to: week_to,
                        entrance_of: entrance_of,
                        entrance_to: entrance_to,
                        hours: hour,
                        days: days
                    };
                }
                this.refreshExitHour++;
            },
            newScheduleLine(){
                this.schedule.push(this.emptySchedule);
            },
            deleteScheduleLine(key){
                delete this.schedule[key];
                this.schedule = this.schedule.filter(n => n);
                this.$nextTick(() => {
                    this.refreshSchedule++;
                });
            },
            detectFirefox(){
                return typeof InstallTrigger !== 'undefined';
            },
            calcHourExit(entrance, workhour){
                let entranceDate = '';
                let resultado = '';
                if(entrance !== null && entrance !== ''){
                    entrance = entrance.split(':');
                    entranceDate = new Date(0,0,0,Number(entrance[0]), Number(entrance[1]),0);
                }

                if(workhour !== null && workhour !== ''){
                    workhour = workhour.split(':');
                    resultado = new Date(entranceDate.getTime() + (workhour[0] * 3600000) + (workhour[1] * 60000));
                }

                return resultado === '' ? '' : resultado.getHours() + ':' + resultado.getMinutes();
            }
        }
    }
</script>

<style>
    .grid-by-width{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    }

    @media (max-width: 1900px) {
        .grid-by-width{
            display: grid;
            grid-template-columns: repeat(6, 1fr);
        }
    }

    @media (max-width: 1750px) {
        .grid-by-width{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
        }
    }

    @media (max-width: 1650px) {
        .grid-by-width{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (max-width: 1500px) {
        .grid-by-width{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 1400px) {
        .grid-by-width{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 1300px) {
        .grid-by-width{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }

</style>