import Schedule from "@/view/Shared/Window/Schedule/main.vue";

const route = [
    {
        path: '/schedule/:from/:id',
        name: 'schedule_by_from',
        component: Schedule,
        props: {showMenu: false, showUser: false, background: true, id: null},
    },
    {
        path: '/schedule/:from/:type/:id',
        name: 'schedule_by_from_type',
        component: Schedule,
        props: {showMenu: false, showUser: false, background: true, id: null},
    },
    {
        path: '/schedule/:from/:type/:id/:staff',
        name: 'schedule_by_from_type_staff',
        component: Schedule,
        props: {showMenu: false, showUser: false, background: true, id: null, staff: null},
    },
];

const routes = [
    ...route,
];

export default routes;