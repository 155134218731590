import Staff from '@/view/Configuration/Staff/main.vue';

import StaffType from './StaffType/router';
import StaffView from './Staff/router';

const route = [
    {
        path: '/configuration/staff',
        name: 'config_staff',
        props: {showMenu: true, showUser: true},
        component: Staff
    }
];

const routes = [
    ...route,
    ...StaffType,
    ...StaffView
];

export default routes;