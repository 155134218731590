var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.code,
                        read_only: _vm.$root._route.params.id !== undefined
                    },"name":{
                        show: true,
                        required: true,
                        other_name: 'location',
                        value: _vm.location,
                        read_only: _vm.$root._route.params.id !== undefined
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_location_type',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_location_type') + ':',
                                text: _vm.$t('location_type') + '*',
                                label: _vm.$t('location_type'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_type',
                                name: 'data[location_type][]'
                            },"selected_input":_vm.location_type,"feed":_vm.getterFeed['location-type']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('location_type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.location_type).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('as_needed')))]),_c('GhChecks',{ref:"refAsNeeded",attrs:{"datalabel":{
                            text: ''
                        },"datainput":{
                            required: false,
                            type: 'checkbox',
                            id: 'check_as_needed',
                            value: 1,
                            name: 'data[as_needed]',
                            selected: _vm.as_needed,
                            disabled: _vm.$root._route.params.view,
                            style: ''
                        }},on:{"click":function($event){return _vm.doAction('refAsNeeded')}}})],1),(!_vm.as_needed)?[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label",class:_vm.$root._route.params.view ? 'T15' : 'T15_b',staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('availability') + (_vm.$root._route.params.view ? ':' : '*')))]),(!_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_data_selector-more T19",staticStyle:{"margin-right":"10px","width":"fit-content","text-align":"right","cursor":"pointer"},on:{"click":_vm.openNewSchedule}},[_vm._v(_vm._s('[ + ]'))])]:_vm._e(),_c('input',{staticClass:"required",attrs:{"id":"has_shedule","label_required":_vm.$t('schedule') + '*',"type":"hidden"},domProps:{"value":_vm.getterCasuistry['schedule'] !== undefined && _vm.getterCasuistry['schedule'].length !== 0 ? true : ''}})],2),(_vm.getterCasuistry['schedule'] !== undefined && _vm.getterCasuistry['schedule'].length !== 0)?[_c('div',{key:'schedule_' + _vm.refreshSchedule,staticClass:"gh-row-content"},[_c('Schedule',{attrs:{"scheduleData":_vm.getterCasuistry['schedule'] !== undefined ? _vm.getterCasuistry['schedule'] : [],"view":true}})],1)]:_vm._e()]:_vm._e(),_c('div',{staticClass:"gh-row-content",style:(!_vm.as_needed && _vm.getterCasuistry['schedule'].length !== 0 ? 'margin-top: -12px;' : '')},[(!_vm.$root._route.params.view)?[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'search',
                                nameStore: 'Feed',
                                imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                id: 'equipment',
                                text: _vm.$t('equipment'),
                                textlocation: _vm.$t('add') + ' ' + _vm.$t('equipment'),
                                textuserselected: _vm.$t('selected_equipment'),
                                actionresult: 'loadSearch',
                                nameResult: 'data[equipment]',
                                supportPermision: false,
                                required: false,
                                loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            },"mainSelector":{
                                name: 'equipment_search',
                                formId: 'data',
                                actionsearch: 'setSearch'
                            },"tableResolt":{
                                header: _vm.header,
                                data: []
                            },"tableSelected":{
                                headersummary: _vm.summary,
                                selected: _vm.equipment
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('equipment') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.equipment))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"refDepartments",attrs:{"datablock":{
                                id: 'data_select_departments',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_departments') + ':',
                                text: _vm.$t('departments'),
                                label: _vm.$t('departments'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            },"datainput":{
                                id: 'value_data_select_departments',
                                name: 'data[departments][]'
                            },"selected_input":_vm.departments,"feed":_vm.getterFeed['department']},on:{"accept":function($event){return _vm.doAction('refDepartments')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('departments') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.departments).join(', ')))])]],2),_c('div',{key:'REFRESH_SPECIALTIES_' + _vm.refreshSpecialties,staticClass:"gh-row-content"},[(_vm.departments.length === 0 || _vm.getterCasuistry['specialties'] === undefined || _vm.getterCasuistry['specialties'].length === 0)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('specialties')))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.departments.length === 0 ? _vm.$t('first_select_departments') : _vm.$t('departments_no_contains_specialty')))])]:(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('specialties') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.specialties).join(', ')))])]:[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_specialties',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_specialties') + ':',
                                text: _vm.$t('specialties'),
                                label: _vm.$t('specialties'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_specialties',
                                name: 'data[specialties][]'
                            },"selected_input":_vm.specialties,"feed":_vm.getterCasuistry['specialties']}})]],2),_c('div',{key:'REFRESH_STAFF_' + _vm.refreshStaff,staticClass:"gh-row-content"},[(_vm.departments.length === 0 || _vm.getterCasuistry['staff'] === undefined || _vm.getterCasuistry['staff'].length === 0)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('staff')))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.departments.length === 0 ? _vm.$t('first_select_departments') : _vm.$t('departments_no_contains_staff')))])]:(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('staff') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.staff).join(', ')))])]:[_c('GhDataSelector',{ref:"refStaff",attrs:{"datablock":{
                                id: 'data_select_staff',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_staff') + ':',
                                text: _vm.$t('staff'),
                                label: _vm.$t('staff'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_staff',
                                name: 'data[staff][]'
                            },"selected_input":_vm.staff,"feed":_vm.getterCasuistry['staff']},on:{"accept":function($event){return _vm.doAction('refStaff')}}})]],2),(Object.keys(_vm.staff).length !== 0)?_vm._l((_vm.staff),function(item,key){return _c('div',{key:'SPECIFIC_KEY_STAFF_' + key},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label",class:_vm.$root._route.params.view ? 'T15' : 'T15_b',staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(item + (_vm.$root._route.params.view ? ':' : '*')))]),(!_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_data_selector-more T19",staticStyle:{"margin-right":"10px","width":"fit-content","text-align":"right","cursor":"pointer"},on:{"click":function($event){return _vm.openNewScheduleStaff(key)}}},[_vm._v(_vm._s('[ + ]'))])]:_vm._e(),_c('input',{staticClass:"required",attrs:{"id":key + '_has_shedule',"label_required":item + ' ' + _vm.$t('pending_assign_schedule').toLowerCase() + '*',"type":"hidden"},domProps:{"value":_vm.getterCasuistry[key + 'schedule'] !== undefined && _vm.getterCasuistry[key + 'schedule'].length !== 0 ? true : ''}})],2),(_vm.getterCasuistry[key + 'schedule'] !== undefined && _vm.getterCasuistry[key + 'schedule'].length !== 0)?[_c('div',{key:'schedule_staff_' + key + '_' + _vm.refreshSchedule,staticClass:"gh-row-content"},[_c('Schedule',{attrs:{"scheduleData":_vm.getterCasuistry[key + 'schedule'] !== undefined ? _vm.getterCasuistry[key + 'schedule'] : [],"view":true}})],1)]:_vm._e()],2)}):_vm._e()],2)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }