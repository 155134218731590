var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                id: 'staff'
            },"header":_vm.header,"data":_vm.getterList.staff !== undefined && _vm.getterList.staff['staff'] !== undefined ? _vm.getterList.staff['staff'].data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList.staff['staff'].actions.view)?[_c('router-link',{attrs:{"to":{name: 'config_staff_staff_view', params: {id: itemProp.id, view: true}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_view_staff_' + itemProp.id,
                                text: _vm.$t('view'),
                                icon: require('@/assets/gh_new_dessign/view.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.staff['staff'].actions.modify)?[_c('router-link',{attrs:{"to":{name: 'config_staff_staff_modify', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_edit_staff_' + itemProp.id,
                                text: _vm.$t('modify'),
                                icon: require('@/assets/gh_new_dessign/edit.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.staff['staff'].actions.sick_leave && itemProp.sick_leave)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_sick_leave_staff_' + itemProp.id,
                            text: _vm.$t('sick_leave'),
                            icon: require('@/assets/gh_new_dessign/return.svg')
                        }},on:{"click":function($event){return _vm.doAction('sick_leave', itemProp)}}})]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterList.staff['staff'].actions.add)?[_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'config_staff_staff'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_new_staff',
                                    text: _vm.$t('add')
                                }}})],1)],1)]:_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }