<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: code,
                            read_only: $root._route.params.id !== undefined
                        }"
                        :name="{
                            show: true,
                            required: true,
                            other_name: 'location',
                            value: location,
                            read_only: $root._route.params.id !== undefined
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_location_type',
                                    class: 'T15_b',
                                    subtitle: $t('select_location_type') + ':',
                                    text: $t('location_type') + '*',
                                    label: $t('location_type'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_type',
                                    name: 'data[location_type][]'
                                }"
                                :selected_input="location_type"
                                :feed="getterFeed['location-type']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('location_type') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(location_type).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('as_needed')}}</label>
                        <GhChecks
                            :datalabel="{
                                text: ''
                            }"
                            :datainput="{
                                required: false,
                                type: 'checkbox',
                                id: 'check_as_needed',
                                value: 1,
                                name: 'data[as_needed]',
                                selected: as_needed,
                                disabled: $root._route.params.view,
                                style: ''
                            }"
                            ref="refAsNeeded"
                            @click="doAction('refAsNeeded')"
                        />
                    </div>
                    <template v-if="!as_needed">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label" :class="$root._route.params.view ? 'T15' : 'T15_b'" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('availability') + ($root._route.params.view ? ':' : '*')}}</label>
                            <template v-if="!$root._route.params.view">
                                <label @click="openNewSchedule" class="gh_data_selector-more T19" style="margin-right: 10px; width: fit-content; text-align: right;cursor: pointer;">{{'[ + ]'}}</label>
                            </template>
                            <input id="has_shedule" :label_required="$t('schedule') + '*'" type="hidden" class="required" :value="getterCasuistry['schedule'] !== undefined && getterCasuistry['schedule'].length !== 0 ? true : ''">
                        </div>
                        <template v-if="getterCasuistry['schedule'] !== undefined && getterCasuistry['schedule'].length !== 0">
                            <div class="gh-row-content" :key="'schedule_' + refreshSchedule">
                                <Schedule :scheduleData="getterCasuistry['schedule'] !== undefined ? getterCasuistry['schedule'] : []" :view="true"/>
                            </div>
                        </template>
                    </template>
                    <div class="gh-row-content" :style="!as_needed && getterCasuistry['schedule'].length !== 0 ? 'margin-top: -12px;' : ''">
                        <template v-if="!$root._route.params.view">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'search',
                                    nameStore: 'Feed',
                                    imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                    id: 'equipment',
                                    text: $t('equipment'),
                                    textlocation: $t('add') + ' ' + $t('equipment'),
                                    textuserselected: $t('selected_equipment'),
                                    actionresult: 'loadSearch',
                                    nameResult: 'data[equipment]',
                                    supportPermision: false,
                                    required: false,
                                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                }"
                                :mainSelector="{
                                    name: 'equipment_search',
                                    formId: 'data',
                                    actionsearch: 'setSearch'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: []
                                }"
                                :tableSelected="{
                                    headersummary: summary,
                                    selected: equipment
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('equipment') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{equipment}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_departments',
                                    class: 'T15_b',
                                    subtitle: $t('select_departments') + ':',
                                    text: $t('departments'),
                                    label: $t('departments'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: false
                                }"
                                :datainput="{
                                    id: 'value_data_select_departments',
                                    name: 'data[departments][]'
                                }"
                                :selected_input="departments"
                                :feed="getterFeed['department']"
                                ref="refDepartments"
                                @accept="doAction('refDepartments')"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('departments') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(departments).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" :key="'REFRESH_SPECIALTIES_' + refreshSpecialties">
                        <template v-if="departments.length === 0 || getterCasuistry['specialties'] === undefined || getterCasuistry['specialties'].length === 0">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('specialties')}}</label>
                            <label class="gh_text_field-label T15_b">{{departments.length === 0 ? $t('first_select_departments') : $t('departments_no_contains_specialty')}}</label>
                        </template>
                        <template v-else-if="$root._route.params.view">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('specialties') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(specialties).join(', ')}}</label>
                        </template>
                        <template v-else>
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_specialties',
                                    class: 'T15_b',
                                    subtitle: $t('select_specialties') + ':',
                                    text: $t('specialties'),
                                    label: $t('specialties'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_specialties',
                                    name: 'data[specialties][]'
                                }"
                                :selected_input="specialties"
                                :feed="getterCasuistry['specialties']"
                            />
                        </template>
                    </div>
                    <div class="gh-row-content" :key="'REFRESH_STAFF_' + refreshStaff">
                        <template v-if="departments.length === 0 || getterCasuistry['staff'] === undefined || getterCasuistry['staff'].length === 0">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('staff')}}</label>
                            <label class="gh_text_field-label T15_b">{{departments.length === 0 ? $t('first_select_departments') : $t('departments_no_contains_staff')}}</label>
                        </template>
                        <template v-else-if="$root._route.params.view">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('staff') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(staff).join(', ')}}</label>
                        </template>
                        <template v-else>
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_staff',
                                    class: 'T15_b',
                                    subtitle: $t('select_staff') + ':',
                                    text: $t('staff'),
                                    label: $t('staff'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_staff',
                                    name: 'data[staff][]'
                                }"
                                :selected_input="staff"
                                :feed="getterCasuistry['staff']"
                                ref="refStaff"
                                @accept="doAction('refStaff')"
                            />
                        </template>
                    </div>
                    <template v-if="Object.keys(staff).length !== 0">
                        <div v-for="(item, key) in staff" :key="'SPECIFIC_KEY_STAFF_' + key">
                            <div class="gh-row-content">
                                <label class="gh_text_field-label" :class="$root._route.params.view ? 'T15' : 'T15_b'" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{item + ($root._route.params.view ? ':' : '*')}}</label>
                                <template v-if="!$root._route.params.view">
                                    <label @click="openNewScheduleStaff(key)" class="gh_data_selector-more T19" style="margin-right: 10px; width: fit-content; text-align: right;cursor: pointer;">{{'[ + ]'}}</label>
                                </template>
                                <input :id="key + '_has_shedule'" :label_required="item + ' ' + $t('pending_assign_schedule').toLowerCase() + '*'" type="hidden" class="required" :value="getterCasuistry[key + 'schedule'] !== undefined && getterCasuistry[key + 'schedule'].length !== 0 ? true : ''">
                            </div>
                            <template v-if="getterCasuistry[key + 'schedule'] !== undefined && getterCasuistry[key + 'schedule'].length !== 0">
                                <div class="gh-row-content" :key="'schedule_staff_' + key + '_' + refreshSchedule">
                                    <Schedule :scheduleData="getterCasuistry[key + 'schedule'] !== undefined ? getterCasuistry[key + 'schedule'] : []" :view="true"/>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import CodeNameDescription from "@/view/Shared/Form/code_name_description.vue";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapGetters} from "vuex";
    import GhSearchWindow from "fe-gh-float-window-lib";
    import Schedule from "@/view/Shared/Form/schedule.vue";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: 'form_location',
        components: {
            GhChecks,
            Schedule,
            GhSearchWindow,
            GhDataSelector,
            CodeNameDescription,
            LoaderTemplate
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed', 'getterCasuistry'])
        },
        data(){
            return{
                haveData: false,
                tempId: null,
                code: '',
                location: '',
                location_type: [],
                equipment: [],
                as_needed: false,
                departments: [],
                specialties: [],
                staff: [],
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 30%;'},
                    {text: 'equipment_type', field: 'equipment_type', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'location', field: 'location', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'availability', field: 'availability', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'training', field: 'training', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                ],
                summary: {
                    0: 'code',
                    1: 'name'
                },
                refreshSchedule: 0,
                refreshSpecialties: 0,
                refreshStaff: 0
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('destroyFeed', {
                    from: 'location-type'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'department'
                }, {root: true});

                await this.$store.dispatch('removeByType', {
                    from: 'location',
                    type: 'location',
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'location'
            }, {root: true});

            await this.$store.dispatch('destroyCasuistry', {
                from: 'schedule'
            }, {root: true});

            if(Object.keys(this.staff).length !== 0) {
                Object.keys(this.staff).forEach((i) => {
                    this.$store.dispatch('destroyCasuistry', {
                        from: i + 'schedule'
                    }, {root: true});
                });
            }
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'location-type'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'department'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getByType', {
                    from: 'location',
                    type: 'location',
                    id: this.$root._route.params.id
                }, {root: true});

                this.code = this.getterGet.location['location'].code;
                this.location = this.getterGet.location['location'].location;
                this.location_type = this.getterGet.location['location'].location_type;
                this.equipment = this.getterGet.location['location'].equipment;
                this.departments = this.getterGet.location['location'].departments;
                this.specialties = this.getterGet.location['location'].specialties;
                this.as_needed = this.getterGet.location['location'].as_needed;
                this.staff = this.getterGet.location['location'].staff;

                await this.$store.dispatch('getCasuistry', {
                    from: 'schedule',
                    url: 'location/location/' + (this.$root._route.params.id === undefined ? this.tempId : this.$root._route.params.id) + '/schedule'
                }, {root: true});

                var concat_uuids = '';
                if(Object.keys(this.departments).length !== 0) {
                    Object.keys(this.departments).forEach((i) => {
                        concat_uuids += i + '&';
                    });

                    concat_uuids = concat_uuids.substring(0, concat_uuids.length - 1);
                }

                if(concat_uuids !== '') {
                    await this.$store.dispatch('getCasuistry', {
                        from: 'specialties',
                        url: 'specilaty/feed/by-departments/' + concat_uuids,
                    }, {root: true}).then((response) => {
                        if (response !== undefined && response.status === 200) {
                            this.refreshSpecialties++;
                        }
                    });

                    await this.$store.dispatch('getCasuistry', {
                        from: 'staff',
                        url: 'staff/feed/by-departments/' + concat_uuids,
                    }, {root: true}).then((response) => {
                        if (response !== undefined && response.status === 200) {
                            this.refreshStaff++;
                        }
                    });
                }

                if(Object.keys(this.staff).length !== 0) {
                    Object.keys(this.staff).forEach((key) => {
                        this.$store.dispatch('getCasuistry', {
                            from: key + 'schedule',
                            url: 'location/location/' + this.$root._route.params.id + '/' + key + '/schedule'
                        }, {root: true});
                    });
                }
            }

            if(this.$root._route.params.id === undefined){
                await this.$store.dispatch('setByType', {
                    from: 'location',
                    type: 'location',
                    extra_url: 'temp-id',
                    formData: []
                }, {root: true}).then((response) => {
                    if(response.status === 200) {
                        this.tempId = response.data.data;
                    }
                });
            }

            if(this.$root._route.params.view){
                this.equipment = '';
                this.getterGet.location['location'].equipment.forEach((i) => {
                    this.equipment += i.code + ' ' + i.name + ', ';
                });

                this.equipment = this.equipment.substring(0, this.equipment.length - 2);
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            async doAction(reference){
                this.persistance();
                switch (reference){
                    case 'refAsNeeded':
                        this.as_needed = this.$refs[reference].selected;
                        break;
                    case 'refDepartments':
                        this.departments = this.$refs[reference].checkdata;

                        var concat_uuids = '';
                        if(Object.keys(this.$refs[reference].checkdata).length !== 0) {
                            Object.keys(this.$refs[reference].checkdata).forEach((i) => {
                                concat_uuids += i + '&';
                            });

                            concat_uuids = concat_uuids.substring(0, concat_uuids.length - 1);
                        }

                        await this.$store.dispatch('getCasuistry', {
                            from: 'specialties',
                            url: 'specilaty/feed/by-departments/' + concat_uuids,
                        }, {root: true}).then((response) => {
                            if(response !== undefined && response.status === 200){
                                this.refreshSpecialties++;
                            }
                        });

                        await this.$store.dispatch('getCasuistry', {
                            from: 'staff',
                            url: 'staff/feed/by-departments/' + concat_uuids,
                        }, {root: true}).then((response) => {
                            if(response !== undefined && response.status === 200){
                                this.refreshStaff++;
                            }
                        });
                        break;
                    case 'refStaff':
                        this.staff = this.$refs[reference].checkdata;
                        break;
                }
            },
            openNewSchedule(){
                this.persistance();

                this.Global.windowOpen(this.Global.openSecondWindow('schedule_by_from_type', {
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId,
                    from: 'location',
                    type: 'location'
                }),'window_new_schedule');

                var that = this;
                window.UpdatedSelect = async function () {
                    await that.$store.dispatch('getCasuistry', {
                        from: 'schedule',
                        url: 'location/location/' + (that.$root._route.params.id === undefined ? that.tempId : that.$root._route.params.id) + '/schedule'
                    }, {root: true});

                    that.refreshSchedule++;
                }
            },
            openNewScheduleStaff(key){
                this.persistance();

                this.Global.windowOpen(this.Global.openSecondWindow('schedule_by_from_type_staff', {
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId,
                    staff: key,
                    from: 'location',
                    type: 'location'
                }),'window_new_schedule');

                var that = this;
                window.UpdatedSelect = async function () {
                    await that.$store.dispatch('getCasuistry', {
                        from: key + 'schedule',
                        url: 'location/location/' + (that.$root._route.params.id === undefined ? that.tempId : that.$root._route.params.id) + '/' + key + '/schedule'
                    }, {root: true});

                    that.refreshSchedule++;
                }
            },
            persistance(){
                if(this.$root._route.params.id === undefined) {
                    this.code = this.$children[0].$children[0].$refs['RefCode'].datainput.value;
                    this.location = this.$children[0].$children[0].$refs['RefName'].datainput.value;
                }
            }
        }
    }
</script>

<style>
    #data_select_location_type_selector_label, #data_select_fixed_location_selector_label, #data_select_departments_selector_label, #data_select_specialties_selector_label{
        text-align: right;
        width: 150px;
    }
</style>