var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DropSections',{attrs:{"sections":_vm.sections,"result":_vm.getterCounters,"perm":_vm.getterPermission}}),_c(_vm.components.general,{tag:"component",attrs:{"html":_vm.dataPopup.html,"title":_vm.dataPopup.title,"type":_vm.dataPopup.type,"stylePopup":_vm.dataPopup.stylePopup,"subTitle":_vm.dataPopup.subTitle,"bodyText":_vm.dataPopup.bodyText,"showButton":_vm.dataPopup.showButton},on:{"accept":function($event){_vm.dataPopup.allowEmit ? _vm.doEmmit(_vm.dataPopup.typeEmit) : ''}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.dataPopup.showSlot)?[_c('form',{attrs:{"id":"form_popup"}},[(_vm.dataPopup.from === 'sick_leave')?[_c('div',{staticClass:"gh-content-global",staticStyle:{"padding-bottom":"unset !important"}},[_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{
                                                text: _vm.$t('definitive')
                                            },"datainput":{
                                                required: true,
                                                type: 'radio',
                                                id: 'data_sick_leave',
                                                value: 1,
                                                name: 'data[sick_leave]',
                                                selected: false,
                                                style: ''
                                            }}}),_c('GhChecks',{attrs:{"datalabel":{
                                                text: _vm.$t('temporal')
                                            },"datainput":{
                                                required: true,
                                                type: 'radio',
                                                id: 'data_sick_leave',
                                                value: 2,
                                                name: 'data[sick_leave]',
                                                selected: false,
                                                style: 'margin-left: 25px;'
                                            }}})],1)])]:_vm._e()],2)]:_vm._e()]},proxy:true}])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }