<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: getterGet.department !== undefined && getterGet.department['department-specialty'] !== undefined ? getterGet.department['department-specialty'].code : ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.department !== undefined && getterGet.department['department-specialty'] !== undefined ? getterGet.department['department-specialty'].name : ''
                        }"
                        :description="{
                            show: true,
                            required: false,
                            value: getterGet.department !== undefined && getterGet.department['department-specialty'] !== undefined ? getterGet.department['department-specialty'].description : ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                id: 'data_select_staff',
                                class: 'T15_b',
                                subtitle: $t('select_staff') + ':',
                                text: $t('staff') + '*',
                                label: $t('staff'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            }"
                                :datainput="{
                                id: 'value_data_select_staff',
                                name: 'data[staff][]'
                            }"
                                :selected_input="getterGet.department !== undefined && getterGet.department['department-specialty'] !== undefined ? getterGet.department['department-specialty'].staff : []"
                                :feed="getterFeed['staff']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('staff') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.department !== undefined && getterGet.department['department-specialty'] !== undefined ? Object.values(getterGet.department['department-specialty'].staff).join(', ') : ''}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';

    import {mapGetters} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";

    export default {
        name: 'form_department_specialty',
        components: {
            GhDataSelector,
            LoaderTemplate,
            CodeNameDescription
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view) {
                await this.$store.dispatch('destroyFeed', {
                    from: 'staff'
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'department'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'staff'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'department',
                    type: 'department-specialty',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>