<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <DropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import DropSections from '@/view/Shared/Sections/DropSections.vue';

    import {mapGetters} from "vuex";

    import equipment_type from './EquipmentType/main.vue';
    import equipment from './Equipment/main.vue';

    export default {
        name: 'configuration_equipment',
        components: {
            DropSections,
            LoaderTemplate
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'equipment_type', component: equipment_type, result: 'equipment_type', second_text: 'results'},
                    {id: 2, name: 'equipment', component: equipment, result: 'equipment', second_text: 'results'}
                ],
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'equipment'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'equipment'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'equipment'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>