import Department from '@/view/Configuration/Department/main.vue';

import DepartmentSpecialty from './DepartmentSpecialty/router';
import ViewDepartment from './Department/router';

const route = [
    {
        path: '/configuration/department',
        name: 'config_department',
        props: {showMenu: true, showUser: true},
        component: Department
    }
];

const routes = [
    ...route,
    ...DepartmentSpecialty,
    ...ViewDepartment
];

export default routes;