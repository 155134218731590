<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <DropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import DropSections from '@/view/Shared/Sections/DropSections.vue';

    import {mapGetters} from "vuex";

    import w2_priority from './W2Priority/main.vue';
    import procedure_subcategory from './ProcedureSubcategory/main.vue';
    import procedure_category from './ProcedureCategory/main.vue';
    import pcats_code from './PcatsCode/main.vue';
    import procedure from './Procedure/main.vue';

    export default {
        name: 'configuration_procedure',
        components: {
            LoaderTemplate,
            DropSections
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'w2_priority', component: w2_priority, result: 'w2_priority', second_text: 'results'},
                    {id: 2, name: 'procedure_subcategory', component: procedure_subcategory, result: 'procedure_subcategory', second_text: 'results'},
                    {id: 3, name: 'procedure_category', component: procedure_category, result: 'procedure_category', second_text: 'results'},
                    {id: 4, name: 'pcats_code', component: pcats_code, result: 'pcats_code', second_text: 'results'},
                    {id: 5, name: 'procedure', component: procedure, result: 'procedure', second_text: 'results'}
                ],
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'procedure'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'procedure'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'procedure'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>