var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('GhLogin',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"display":"flex","padding-bottom":"-50px"}},[_c('div',[_c('img',{attrs:{"alt":"","src":require('../../assets/login/sickkids-logo-header.png'),"width":"312"}})])])]},proxy:true},{key:"content",fn:function(){return [_c('div',{style:(_vm.getterErrorAuthentication.flag ? 'background-color: #ff04042b; margin-bottom: 20px; width: 312px; padding: 10px;' : '')},[(_vm.getterErrorAuthentication.flag)?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.getterErrorAuthentication.error))])]:_vm._e()],2),_c('div',{staticClass:"container-user"},[_c('GhInputLogin',{ref:"GhInputUser",attrs:{"datalabel":{
                        id: 'user_label',
                        text: _vm.$t('password')
                    },"datainput":{
                        id: 'user_labelf',
                        type: 'password',
                        name: 'password',
                        class: 'password',
                        error: '',
                        show_message: false
                    }},on:{"keyup":_vm.enterSubmit}})],1),_c('div',{staticClass:"container-password",staticStyle:{"padding-top":"46px"}},[_c('GhInputLogin',{ref:"GhInputPassword",attrs:{"datalabel":{
                        id: 'pass_label',
                        text: _vm.$t('repeat_password')
                    },"datainput":{
                        id: 'pass_labelf',
                        type: 'password',
                        name: 'repeat_password',
                        class: 'password',
                        error: '',
                        show_message: true
                    }},on:{"keyup":_vm.enterSubmit}})],1),_c('div',{staticClass:"botonera",staticStyle:{"margin-top":"66px"}},[_c('GhButton',{attrs:{"datainput":{
                        id: 'enter_button',
                        text: _vm.$t('regenerate_password'),
                        class: 'container-md_button text-inline t13',
                        style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'
                    }},on:{"click":function($event){return _vm.submit()}}})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }