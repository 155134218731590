import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

import Login from './Login/router';
import PasswordRegenerate from './PasswordRegenerate/router';
import Configuration from './Configuration/router';
import Patients from './Patients/router';
import Plan from './Plan/router';
import Asvertisements from './Advertisements/router';
import Permissions from './Permissions/router';
import SearchFloating from './SearchFloating/route';
import Shared from './Shared/router'

const routes = [
    ...Login,
    ...PasswordRegenerate,
    ...Configuration,
    ...Patients,
    ...Plan,
    ...Asvertisements,
    ...Permissions,
    ...SearchFloating,
    ...Shared
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    GhTabs: [],
    GhSubTabsConfiguration: [],
    GhSubTabsPlan: [],
    GhSubTabsAdvertisements: [],
    GhSubTabsPermissions: [],
    routes
});

export default router;