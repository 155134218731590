import Equipment from '@/view/Configuration/Equipment/main.vue';

import EquipmentView from './Equipment/router';
import EquipmentType from './EquipmentType/router';

const route = [
    {
        path: '/configuration/equipment',
        name: 'config_equipment',
        props: {showMenu: true, showUser: true},
        component: Equipment
    }
];

const routes = [
    ...route,
    ...EquipmentView,
    ...EquipmentType
];

export default routes;