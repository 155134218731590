import {API_URL} from "@/common/config";
import axios from "axios";
import router from "../../routes/router";
import Global from "../Global";

export default {
    state: {
        errorAuthentication: [],
        tabs: [],
        configurationTabs: [],
        planTabs: [],
        advertisementsTabs: [],
        permissionsTabs: [],
        token: '',
        tokenTime: 0,
        flagErrorAuthentication: false,
        flagTabs: false,
        flagConfigurationTabs: false,
        flagPlanTabs: false,
        flagAdvertisementsTabs: false,
        flagPermissionsTabs: false,
        flagToken: false,
        flagTokenTime: false
    },
    mutations: {
        loadErrorAuthentication(state, payload){
            state.flagErrorAuthentication = !state.flagErrorAuthentication;
            state.errorAuthentication = payload;
        },
        loadTabsWithPerm(state,payload){
            state.flagTabs = !state.flagTabs;
            state.tabs = payload;
        },
        loadConfigurationTabsWithPerm(state,payload){
            state.flagConfigurationTabs = !state.flagConfigurationTabs;
            state.configurationTabs = payload;
        },
        loadPlanTabsWithPerm(state,payload){
            state.flagPlanTabs = !state.flagPlanTabs;
            state.planTabs = payload;
        },
        loadAdvertisementsTabsWithPerm(state,payload){
            state.flagAdvertisementsTabs = !state.flagAdvertisementsTabs;
            state.advertisementsTabs = payload;
        },
        loadPermissionsTabsWithPerm(state,payload){
            state.flagPermissionsTabs = !state.flagPermissionsTabs;
            state.permissionsTabs = payload;
        },
        loadUserToken(state, payload){
            state.flagToken = !state.flagToken;
            state.token = payload;
        },
        loadUserTokenTime(state, payload){
            state.flagTokenTime = !state.flagTokenTime;
            state.tokenTime = payload;
        }
    },
    getters: {
        getterTabsByPerm(state){
            const dummy = state.flagTabs; // eslint-disable-line no-unused-vars
            return state.tabs;
        },
        getterConfigurationTabsByPerm(state){
            const dummy = state.flagConfigurationTabs; // eslint-disable-line no-unused-vars
            return state.configurationTabs;
        },
        getterPlanTabsByPerm(state){
            const dummy = state.flagPlanTabs; // eslint-disable-line no-unused-vars
            return state.planTabs;
        },
        getterAdvertisementsTabsByPerm(state){
            const dummy = state.flagAdvertisementsTabs; // eslint-disable-line no-unused-vars
            return state.advertisementsTabs;
        },
        getterPermissionsTabsByPerm(state){
            const dummy = state.flagPermissionsTabs; // eslint-disable-line no-unused-vars
            return state.permissionsTabs;
        },
        getterErrorAuthentication(state){
            const dummy = state.flagErrorAuthentication; // eslint-disable-line no-unused-vars
            return state.errorAuthentication;
        },
        getterUserToken(state){
            const dummy = state.flagToken; // eslint-disable-line no-unused-vars
            return state.token;
        }
    },
    actions: {
        async doRegenerate(state, credentials){
            let request = API_URL + "reset/rmbpwd/" + credentials.token;
            let formData = new FormData();

            formData.append("password", credentials.password);
            formData.append("repeat_password", credentials.repeat_password);

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        state.commit('loadErrorAuthentication', {
                            error: '',
                            flag: false
                        });
                        router.push({name: 'login'});
                    }
                },
                error => {
                    if(error.status !== ''){
                        state.commit('loadErrorAuthentication', {
                            error: 'Password does not match, try again',
                            flag: true
                        });
                    }
                }
            )
        },
        async checkRegenerate(state, credentials){
            let request = API_URL + "rmbpwd/" + credentials.token;
            let formData = new FormData();

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                error => {
                    if(error.status !== 200){
                        state.commit('loadErrorAuthentication', {
                            error: 'The 30 minutes offered to regenerate the password have expired',
                            flag: true
                        });
                    }else{
                        state.commit('loadErrorAuthentication', {
                            error: '',
                            flag: false
                        });
                    }
                }
            )
        },
        async doLogin(state, credentials){
            localStorage.clear();

            let request = API_URL + "auth/login";
            let formData = new FormData();

            formData.append("username", credentials.username);
            formData.append("password", credentials.password);

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        state.commit("loadUserToken", response.data.data);

                        localStorage.setItem('user',response.data.data.username);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('expire_date',response.data.data.expire_date);

                        if(response.data.data.who_is){
                            router.options.GhTabs = [];
                            state.commit('loadTabsWithPerm', []);
                            router.push({
                                name: 'patient_view',
                                params: {
                                    id: response.data.data.uuid_user,
                                    view: true
                                }
                            });
                        }else{
                            await this.dispatch('getTabsWithPerm');
                            router.push({name: state.state.tabs[0].value});
                        }

                        var dformat = '';
                        const date = new Date();
                        dformat = [
                            date.getFullYear(),
                            ('0' + (date.getMonth()+1)).slice(-2),
                            ('0' + date.getDate()).slice(-2)
                            ].join('-') + ' ' + [
                                ('0' + date.getHours()).slice(-2),
                                ('0' + date.getMinutes()).slice(-2),
                            ].join(':');

                        var diff =(new Date(response.data.data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;

                        diff /= (60 * 60);

                        state.commit("loadUserTokenTime", diff);
                        state.commit('loadErrorAuthentication', {
                            error: '',
                            flag: false
                        });
                    }else if(response.data.error){
                        state.commit("loadErrorAuthentication", {
                            error: response.data.error,
                            flag: true
                        });
                    }
                },
                error => {
                    state.commit("loadErrorAuthentication", {
                        error: error.response.data.error,
                        flag: true
                    });
                }
            )
        },
        async goRememberPassword(state, credentials){
            let request = API_URL + "rmbpwd";

            let formData = new FormData();
            formData.append("cif", credentials.cif);
            formData.append("email", credentials.email);

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        Global.openPopUp('rememeber_password_success');
                    }
                },
                error => {
                    if(error.status !== undefined){
                        Global.openPopUp('remember_password_error');
                    }
                }
            )
        },
        async getTabsWithPerm(state){
            await axios.get(
                API_URL + "app-modules"
            ).then(
                response => {
                    if(response.status === 200){
                        let tabs = [];
                        let subtab = [];
                        response.data.data.forEach((item) => {
                            tabs.push({
                                id: item.id,
                                value: item.value
                            });

                            if(item['sub-modules'] !== undefined){
                                item['sub-modules'].forEach((submodules) => {
                                    if(submodules.have_permission){
                                        if(subtab[item.value] === undefined) subtab[item.value] = [];
                                        subtab[item.value].push({
                                            id: submodules.id,
                                            value: submodules.value,
                                            call: submodules.call
                                        });
                                    }
                                });
                            }
                        });

                        router.options.GhTabs = tabs;
                        router.options.GhSubTabsConfiguration = subtab['configuration'];
                        router.options.GhSubTabsPlan = subtab['plan'];
                        router.options.GhSubTabsAdvertisements = subtab['advertisements'];
                        router.options.GhSubTabsPermissions = subtab['permissions'];

                        localStorage.setItem('TabItem', response.data.data[0].value);
                        state.commit('loadTabsWithPerm', tabs);
                        state.commit('loadConfigurationTabsWithPerm', subtab['configuration']);
                        state.commit('loadPlanTabsWithPerm', subtab['plan']);
                        state.commit('loadAdvertisementsTabsWithPerm', subtab['advertisements']);
                        state.commit('loadPermissionsTabsWithPerm', subtab['permissions']);
                    }
                }
            )
        },
        async SET_LOGOUT(state){
            let requestLogin = API_URL + "auth/logout";

            try {
                const response = await axios.post(requestLogin, null, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response.status === 200) {
                    state.commit("loadUserToken", "");
                    state.commit("loadUserTokenTime", "");

                } else if (response.data.error) {
                    state.commit("loadErrorAuthentication", response.data.error);
                }

                localStorage.clear();
                if (response.data.logout_callback) {
                    window.location.replace(response.data.logout_callback);
                }else{
                    router.push({name: 'login'});
                }
                return;
            } catch (error) {
                let the_error = { error: { code: error.code, msg: error.error } };
                state.commit("loadErrorAuthentication", the_error);
            }
        },
    }
}