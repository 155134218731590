<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('sn') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_code',
                                    name: 'data[code]',
                                    style: 'width: 200px',
                                    value: code,
                                    type: 'text'
                                }"
                                ref="RefCode"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('sn') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{code}}</label>
                        </template>
                    </div>
                    <CodeNameDescription
                        :code="{
                            show: false,
                            required: false,
                            value: '',
                            read_only: ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: name,
                            read_only: $root._route.params.id !== undefined
                        }"
                        :description="{
                            show: true,
                            required: false,
                            value: description,
                            read_only: $root._route.params.id !== undefined
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_equipment_type',
                                    class: 'T15_b',
                                    subtitle: $t('select_equipment_type') + ':',
                                    text: $t('equipment_type') + '*',
                                    label: $t('equipment_type'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_type',
                                    name: 'data[equipment_type][]'
                                }"
                                :selected_input="equipment_type"
                                :feed="getterFeed['equipment-type']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('equipment_type') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(equipment_type).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <GhChecks v-if="!$root._route.params.view"
                            :datalabel="{
                                text: ''
                            }"
                            :datainput="{
                                required: false,
                                type: 'checkbox',
                                id: 'check_has_fixed_location',
                                value: 1,
                                name: 'data[has_fixed_location]',
                                selected: has_fixed_location,
                                disabled: $root._route.params.view,
                                style: 'margin-left: 10px;'
                            }"
                            ref="refHasFixedLocation"
                            @click="showSelectable('show_fixed_location','refHasFixedLocation')"
                        />
                        <template v-if="!$root._route.params.view">
                            <template v-if="show_fixed_location">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'data_select_fixed_location',
                                        class: 'T15_b',
                                        subtitle: $t('select_fixed_location') + ':',
                                        text: $t('fixed_location') + '*',
                                        label: $t('fixed_location'),
                                        type: 'radio',
                                        columns: 3,
                                        style: '',
                                        required: true
                                    }"
                                    :datainput="{
                                        id: 'value_data_select_type',
                                        name: 'data[fixed_location][]'
                                    }"
                                    :selected_input="fixed_location"
                                    :feed="getterFeed['fixed-location']"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px; text-align: right;">{{$t('fixed_location')}}</label>
                            </template>
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('fixed_location') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(fixed_location).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <div class="gh-row-content">
                            <GhChecks v-if="!$root._route.params.view"
                                :datalabel="{
                                    text: ''
                                }"
                                :datainput="{
                                    required: false,
                                    type: 'checkbox',
                                    id: 'check_has_training',
                                    value: 1,
                                    name: 'data[has_training]',
                                    selected: has_training,
                                    disabled: $root._route.params.view,
                                    style: 'margin-left: 10px;'
                                }"
                                ref="refHasTraining"
                                @click="showSelectable('show_has_training','refHasTraining')"
                            />
                            <template v-if="!$root._route.params.view">
                                <template v-if="show_has_training">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'data_select_training_needed',
                                            class: 'T15_b',
                                            subtitle: $t('select_training') + ':',
                                            text: $t('training_needed') + '*',
                                            label: $t('training_needed'),
                                            type: 'checkbox',
                                            columns: 3,
                                            style: '',
                                            required: true
                                        }"
                                        :datainput="{
                                            id: 'value_data_select_type',
                                            name: 'data[training][]'
                                        }"
                                        :selected_input="training"
                                        :feed="feed_training"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px; text-align: right;">{{$t('training_needed')}}</label>
                                </template>
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('training_needed') + ':'}}</label>
                                <label class="gh_text_field-label T15_b">{{Object.values(training).join(', ')}}</label>
                            </template>
                        </div>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('as_needed')}}</label>
                        <GhChecks
                            :datalabel="{
                                text: ''
                            }"
                            :datainput="{
                                required: false,
                                type: 'checkbox',
                                id: 'check_as_needed',
                                value: 1,
                                name: 'data[as_needed]',
                                selected: as_needed,
                                disabled: $root._route.params.view,
                                style: ''
                            }"
                            ref="refAsNeeded"
                            @click="showSelectable('as_needed','refAsNeeded')"
                        />
                    </div>
                    <template v-if="!as_needed">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('availability') + ($root._route.params.view ? ':' : '')}}</label>
                            <template v-if="!$root._route.params.view">
                                <label @click="openNewSchedule" class="gh_data_selector-more T19" style="margin-right: 10px; width: fit-content; text-align: right;cursor: pointer;">{{'[ + ]'}}</label>
                            </template>
                        </div>
                        <template v-if="getterCasuistry['schedule'] !== undefined && getterCasuistry['schedule'].length !== 0">
                            <div class="gh-row-content" :key="'schedule_' + refreshSchedule">
                                <Schedule :scheduleData="getterCasuistry['schedule'] !== undefined ? getterCasuistry['schedule'] : []" :view="true"/>
                            </div>
                        </template>
                    </template>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import CodeNameDescription from "@/view/Shared/Form/code_name_description.vue";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapGetters} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhChecks from "fe-gh-checks-lib";
    import Schedule from "@/view/Shared/Form/schedule.vue";

    export default {
        name: 'form_equipment',
        components: {
            Schedule,
            GhChecks,
            GhInputTextField,
            GhDataSelector,
            CodeNameDescription,
            LoaderTemplate
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed', 'getterCasuistry'])
        },
        data(){
            return{
                haveData: false,
                show_fixed_location: false,
                show_has_training: false,
                has_fixed_location: false,
                has_training: false,
                tempId: null,
                code: '',
                name: '',
                description: '',
                equipment_type: [],
                fixed_location: [],
                training: [],
                feed_training: [],
                as_needed: false,
                refreshSchedule: 0
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('destroyFeed', {
                    from: 'equipment-type'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'fixed-location'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'training'
                }, {root: true});

                await this.$store.dispatch('removeByType', {
                    from: 'equipment',
                    type: 'equipment',
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'equipment'
            }, {root: true});

            await this.$store.dispatch('destroyCasuistry', {
                from: 'schedule'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'equipment-type'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'fixed-location'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'training'
                }, {root: true});

                if(Object.keys(this.getterFeed['training']).length !== 0){
                    Object.values(this.getterFeed['training']).forEach((values) => {
                        this.feed_training[values.id] = values.name;
                    })
                }
            }

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getByType', {
                    from: 'equipment',
                    type: 'equipment',
                    id: this.$root._route.params.id
                }, {root: true});

                this.code = this.getterGet.equipment['equipment'].code;
                this.name = this.getterGet.equipment['equipment'].name;
                this.description = this.getterGet.equipment['equipment'].description;
                this.equipment_type = this.getterGet.equipment['equipment'].equipment_type;
                this.show_fixed_location = this.has_fixed_location = this.getterGet.equipment['equipment'].has_fixed_location;
                this.show_has_training = this.has_training = this.getterGet.equipment['equipment'].has_training;
                this.fixed_location = this.getterGet.equipment['equipment'].fixed_location;
                this.training = this.getterGet.equipment['equipment'].training;
                this.as_needed = this.getterGet.equipment['equipment'].as_needed;

                await this.$store.dispatch('getCasuistry', {
                    from: 'schedule',
                    url: 'equipment/equipment/' + (this.$root._route.params.id === undefined ? this.tempId : this.$root._route.params.id) + '/schedule'
                }, {root: true});
            }

            if(this.$root._route.params.id === undefined){
                await this.$store.dispatch('setByType', {
                    from: 'equipment',
                    type: 'equipment',
                    extra_url: 'temp-id',
                    formData: []
                }, {root: true}).then((response) => {
                    if(response.status === 200) {
                        this.tempId = response.data.data;
                    }
                });
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            showSelectable(condition,reference){
                this.persistance();
                if(reference === 'refAsNeeded'){
                    this.as_needed = this.$refs[reference].selected;
                }else {
                    this[condition] = this.$refs[reference].selected;
                }
            },
            openNewSchedule(){
                this.persistance();

                this.Global.windowOpen(this.Global.openSecondWindow('schedule_by_from_type', {
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId,
                    from: 'equipment',
                    type: 'equipment'
                }),'window_new_schedule');

                var that = this;
                window.UpdatedSelect = async function () {
                    await that.$store.dispatch('getCasuistry', {
                        from: 'schedule',
                        url: 'equipment/equipment/' + (that.$root._route.params.id === undefined ? that.tempId : that.$root._route.params.id) + '/schedule'
                    }, {root: true});

                    that.refreshSchedule++;
                }
            },
            persistance(){
                if(this.$root._route.params.id === undefined) {
                    this.code = this.$refs['RefCode'].datainput.value;
                    this.name = this.$children[0].$children[1].$refs['RefName'].datainput.value;
                }
            }
        }
    }
</script>

<style>
    #data_select_equipment_type_selector_label, #data_select_fixed_equipment_selector_label {
        text-align: right;
        width: 150px;
    }

    #data_select_fixed_location_selector_label, #data_select_training_needed_selector_label {
        text-align: right;
        width: 126px;
    }
</style>