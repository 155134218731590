<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <DropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
                <component :is="components.general" :html="dataPopup.html" :title="dataPopup.title" :type="dataPopup.type" :stylePopup="dataPopup.stylePopup" :subTitle="dataPopup.subTitle" :bodyText="dataPopup.bodyText" :showButton="dataPopup.showButton" @accept="dataPopup.allowEmit ? doEmmit(dataPopup.typeEmit) : ''">
                    <template v-slot:content>
                        <template v-if="dataPopup.showSlot">
                            <form id="form_popup">
                                <template v-if="dataPopup.from === 'sick_leave'">
                                    <div class="gh-content-global" style="padding-bottom: unset !important;">
                                        <div class="gh-row-content">
                                            <GhChecks
                                                :datalabel="{
                                                    text: $t('definitive')
                                                }"
                                                :datainput="{
                                                    required: true,
                                                    type: 'radio',
                                                    id: 'data_sick_leave',
                                                    value: 1,
                                                    name: 'data[sick_leave]',
                                                    selected: false,
                                                    style: ''
                                                }"
                                            />
                                            <GhChecks
                                                :datalabel="{
                                                    text: $t('temporal')
                                                }"
                                                :datainput="{
                                                    required: true,
                                                    type: 'radio',
                                                    id: 'data_sick_leave',
                                                    value: 2,
                                                    name: 'data[sick_leave]',
                                                    selected: false,
                                                    style: 'margin-left: 25px;'
                                                }"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </form>
                        </template>
                    </template>
                </component>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import DropSections from '@/view/Shared/Sections/DropSections.vue';
    import GhChecks from "fe-gh-checks-lib";

    import {mapGetters} from "vuex";

    import staff_type from './StaffType/main.vue';
    import staff from './Staff/main.vue';

    import general from "@/view/Shared/Popups/general.vue";

    export default {
        name: 'configuration_staff',
        components: {
            LoaderTemplate,
            DropSections,
            GhChecks
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'staff_type', component: staff_type, result: 'staff_type', second_text: 'results'},
                    {id: 2, name: 'staff', component: staff, result: 'staff', second_text: 'results'}
                ],
                components: {
                    general: general
                },
                dataPopup: {
                    id: null,
                    type: '',
                    title: '',
                    stylePopup: '',
                    subTitle: '',
                    bodyText: '',
                    showButton: false,
                    showSlot: false,
                    allowEmit: false,
                    typeEmit: '',
                    html: false
                },
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'staff'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'staff'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'staff'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        },
        methods:{
            async doEmmit(type){
                switch (type){
                    case 'sick_leave':
                        if(this.Global.checkRequired('form_popup') === 'OK'){
                            const formData = new FormData(document.getElementById('form_popup'));
                            this.$store.dispatch('setByType', {
                                formData: [...formData],
                                id: this.dataPopup.id,
                                from: 'staff',
                                type: 'staff',
                                extra_url: 'sick-leave',
                            }, {root: true}).then(async (response) => {
                                if(response !== undefined && response.status === 200){
                                    await this.$store.dispatch('getPermissionByType', {type: 'staff'}, {root: true});
                                    await this.$store.dispatch('getCountersByType', {type: 'staff'}, {root: true});
                                    await this.$store.dispatch('getListByType', {
                                        from: 'staff',
                                        type: 'staff'
                                    }, {root: true});
                                    this.Global.openPopUp('popup_general');
                                }
                            });
                        }
                        break;
                }
            }
        }
    }
</script>