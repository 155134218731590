import Procedure from '@/view/Configuration/Procedure/main.vue';

import W2Priority from './W2Priority/router';
import ProcedureSubcategory from './ProcedureSubcategory/router';
import ProcedureCategory from './ProcedureCategory/router';
import PcatsCode from './PcatsCode/router';
import ProcedureView from './Procedure/router';

const route = [
    {
        path: '/configuration/procedure',
        name: 'config_procedure',
        props: {showMenu: true, showUser: true},
        component: Procedure
    }
];

const routes = [
    ...route,
    ...W2Priority,
    ...ProcedureSubcategory,
    ...ProcedureCategory,
    ...PcatsCode,
    ...ProcedureView
];

export default routes;