var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['procedure-subcategory'] !== undefined ? _vm.getterGet.procedure['procedure-subcategory'].code : ''
                    },"name":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['procedure-subcategory'] !== undefined ? _vm.getterGet.procedure['procedure-subcategory'].name : ''
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }