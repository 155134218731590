var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                                text: _vm.$t('sn') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_code',
                                name: 'data[code]',
                                style: 'width: 200px',
                                value: _vm.code,
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('sn') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.code))])]],2),_c('CodeNameDescription',{attrs:{"code":{
                        show: false,
                        required: false,
                        value: '',
                        read_only: ''
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.name,
                        read_only: _vm.$root._route.params.id !== undefined
                    },"description":{
                        show: true,
                        required: false,
                        value: _vm.description,
                        read_only: _vm.$root._route.params.id !== undefined
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_equipment_type',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_equipment_type') + ':',
                                text: _vm.$t('equipment_type') + '*',
                                label: _vm.$t('equipment_type'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_type',
                                name: 'data[equipment_type][]'
                            },"selected_input":_vm.equipment_type,"feed":_vm.getterFeed['equipment-type']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('equipment_type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.equipment_type).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?_c('GhChecks',{ref:"refHasFixedLocation",attrs:{"datalabel":{
                            text: ''
                        },"datainput":{
                            required: false,
                            type: 'checkbox',
                            id: 'check_has_fixed_location',
                            value: 1,
                            name: 'data[has_fixed_location]',
                            selected: _vm.has_fixed_location,
                            disabled: _vm.$root._route.params.view,
                            style: 'margin-left: 10px;'
                        }},on:{"click":function($event){return _vm.showSelectable('show_fixed_location','refHasFixedLocation')}}}):_vm._e(),(!_vm.$root._route.params.view)?[(_vm.show_fixed_location)?[_c('GhDataSelector',{attrs:{"datablock":{
                                    id: 'data_select_fixed_location',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_fixed_location') + ':',
                                    text: _vm.$t('fixed_location') + '*',
                                    label: _vm.$t('fixed_location'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                },"datainput":{
                                    id: 'value_data_select_type',
                                    name: 'data[fixed_location][]'
                                },"selected_input":_vm.fixed_location,"feed":_vm.getterFeed['fixed-location']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"125px","min-width":"125px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('fixed_location')))])]]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('fixed_location') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.fixed_location).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?_c('GhChecks',{ref:"refHasTraining",attrs:{"datalabel":{
                                text: ''
                            },"datainput":{
                                required: false,
                                type: 'checkbox',
                                id: 'check_has_training',
                                value: 1,
                                name: 'data[has_training]',
                                selected: _vm.has_training,
                                disabled: _vm.$root._route.params.view,
                                style: 'margin-left: 10px;'
                            }},on:{"click":function($event){return _vm.showSelectable('show_has_training','refHasTraining')}}}):_vm._e(),(!_vm.$root._route.params.view)?[(_vm.show_has_training)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'data_select_training_needed',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_training') + ':',
                                        text: _vm.$t('training_needed') + '*',
                                        label: _vm.$t('training_needed'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: '',
                                        required: true
                                    },"datainput":{
                                        id: 'value_data_select_type',
                                        name: 'data[training][]'
                                    },"selected_input":_vm.training,"feed":_vm.feed_training}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"125px","min-width":"125px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('training_needed')))])]]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('training_needed') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.training).join(', ')))])]],2)]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('as_needed')))]),_c('GhChecks',{ref:"refAsNeeded",attrs:{"datalabel":{
                            text: ''
                        },"datainput":{
                            required: false,
                            type: 'checkbox',
                            id: 'check_as_needed',
                            value: 1,
                            name: 'data[as_needed]',
                            selected: _vm.as_needed,
                            disabled: _vm.$root._route.params.view,
                            style: ''
                        }},on:{"click":function($event){return _vm.showSelectable('as_needed','refAsNeeded')}}})],1),(!_vm.as_needed)?[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('availability') + (_vm.$root._route.params.view ? ':' : '')))]),(!_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_data_selector-more T19",staticStyle:{"margin-right":"10px","width":"fit-content","text-align":"right","cursor":"pointer"},on:{"click":_vm.openNewSchedule}},[_vm._v(_vm._s('[ + ]'))])]:_vm._e()],2),(_vm.getterCasuistry['schedule'] !== undefined && _vm.getterCasuistry['schedule'].length !== 0)?[_c('div',{key:'schedule_' + _vm.refreshSchedule,staticClass:"gh-row-content"},[_c('Schedule',{attrs:{"scheduleData":_vm.getterCasuistry['schedule'] !== undefined ? _vm.getterCasuistry['schedule'] : [],"view":true}})],1)]:_vm._e()]:_vm._e()],2)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }