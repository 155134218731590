<template>
    <div class="login">
        <GhLogin>
            <template v-slot:header>
                <div style="display: flex; padding-bottom: -50px;">
                    <div>
                        <img alt="" :src="require('../../assets/login/sickkids-logo-header.png')" width="312"/>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div :style="getterErrorAuthentication.flag ? 'background-color: #ff04042b; margin-bottom: 20px; width: 312px; padding: 10px;' : ''">
                    <template v-if="getterErrorAuthentication.flag">
                        <label class="gh_text_field-label T15_b" style="color: red;">{{getterErrorAuthentication.error}}</label>
                    </template>
                </div>
                <div class="container-user">
                    <GhInputLogin
                        :datalabel="{
                            id: 'user_label',
                            text: $t('password')
                        }"
                        :datainput="{
                            id: 'user_labelf',
                            type: 'password',
                            name: 'password',
                            class: 'password',
                            error: '',
                            show_message: false
                        }"
                        @keyup="enterSubmit"
                        ref="GhInputUser"
                    />
                </div>
                <div class="container-password" style="padding-top: 46px">
                    <GhInputLogin
                        :datalabel="{
                            id: 'pass_label',
                            text: $t('repeat_password')
                        }"
                        :datainput="{
                            id: 'pass_labelf',
                            type: 'password',
                            name: 'repeat_password',
                            class: 'password',
                            error: '',
                            show_message: true
                        }"
                        @keyup="enterSubmit"
                        ref="GhInputPassword"
                    />
                </div>
                <div class="botonera" style="margin-top: 66px;">
                    <GhButton
                        :datainput="{
                            id: 'enter_button',
                            text: $t('regenerate_password'),
                            class: 'container-md_button text-inline t13',
                            style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'
                        }"
                        @click="submit()"
                    />
                </div>
            </template>
        </GhLogin>
    </div>
</template>

<script>
    import GhLogin from 'fe-gh-login-lib';
    import GhInputLogin from 'fe-gh-input-login-lib';
    import GhButton from 'fe-gh-button-lib';

    import {mapGetters} from "vuex";

    export default {
        name: 'login_main',
        components: {
            GhLogin,
            GhInputLogin,
            GhButton
        },
        computed: {
            ...mapGetters(['getterErrorAuthentication'])
        },
        data(){
            return {
                refreshError: 0,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('checkRegenerate', {
                token: this.$root._route.params.token
            }, {root: true});
        },
        methods: {
            async submit(){
                await this.$store.dispatch('doRegenerate', {
                    password: this.$refs.GhInputUser.$el.children[1].children[0].value,
                    repeat_password: this.$refs.GhInputPassword.$el.children[1].children[0].value,
                    token: this.$root._route.params.token
                },{root:true});
            },
            enterSubmit: function(e){
                if (e.keyCode === 13) {
                    this.submit();
                }
            }
        }
    }
</script>

<style>
    @import "~fe-gh-login-lib/dist/fe-gh-login-lib.css";
    @import "~fe-gh-input-login-lib/dist/fe-gh-input-login-lib.css";
    @import "~fe-gh-action-lib/dist/fe-gh-action-lib.css";
    @import "../../assets/css/login/style.css";

    .container-password {
        top: 265px;
    }
</style>