import Training from '@/view/Configuration/Training/main.vue';

import TrainingType from './TrainingType/router';
import TrainingView from './Training/router';

const route = [
    {
        path: '/configuration/training',
        name: 'config_training',
        props: {showMenu: true, showUser: true},
        component: Training
    }
];

const routes = [
    ...route,
    ...TrainingType,
    ...TrainingView
];

export default routes;