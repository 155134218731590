var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.code,
                        read_only: _vm.$root._route.params.id !== undefined,
                    },"name":{
                        show: false,
                        required: false,
                        value: ''
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                text: _vm.$t('name') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_name',
                                name: 'data[name]',
                                style: 'width: 200px',
                                value: _vm.name,
                                type: 'text'
                            }}}),_c('GhInputTextField',{ref:"RefSurname",attrs:{"datalabel":{
                                text: _vm.$t('surname') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_surname',
                                name: 'data[surname]',
                                style: 'width: 200px',
                                value: _vm.surname,
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.name))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surname') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.surname))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhInputTextField',{ref:"RefEmail",attrs:{"datalabel":{
                                text: _vm.$t('email') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_email',
                                name: 'data[email]',
                                style: 'width: 565px',
                                value: _vm.email,
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('email') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.email))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhDataSelector',{ref:"RefStaffType",attrs:{"datablock":{
                                id: 'data_select_staff_type',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_staff_type') + ':',
                                text: _vm.$t('staff_type') + '*',
                                label: _vm.$t('staff_type'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_staff_type',
                                name: 'data[staff_type]'
                            },"selected_input":_vm.staff_type,"feed":_vm.getterFeed['staff-type']},on:{"accept":function($event){return _vm.checkSelector('RefStaffType')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('staff_type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.staff_type).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{ref:"RefTypeScheduleShift",attrs:{"datalabel":{
                            text: _vm.$t('shift')
                        },"datainput":{
                            required: false,
                            type: 'radio',
                            id: 'data_type_schedule_shift',
                            value: 1,
                            name: 'data[type_schedule]',
                            selected: _vm.type_schedule === 1,
                            disabled: _vm.$root._route.params.view
                        }},on:{"click":function($event){return _vm.checkSelector('RefTypeScheduleShift')}}}),_c('GhChecks',{ref:"RefTypeScheduleNewSchedule",attrs:{"datalabel":{
                            text: _vm.$t('schedule')
                        },"datainput":{
                            required: false,
                            type: 'radio',
                            id: 'data_type_schedule_new_schedule',
                            value: 2,
                            name: 'data[type_schedule]',
                            selected: _vm.type_schedule === 2,
                            disabled: _vm.$root._route.params.view,
                            style: 'margin-left: 25px;'
                        }},on:{"click":function($event){return _vm.checkSelector('RefTypeScheduleNewSchedule')}}}),(!_vm.$root._route.params.view && _vm.type_schedule === 2)?[_c('GhAction',{staticStyle:{"margin-top":"-1px","margin-left":"20px"},attrs:{"dataction":{
                                id: 'action_modify_schedule',
                                text: _vm.$t('modify'),
                                icon: require('@/assets/gh_new_dessign/view.svg')
                            }},on:{"click":_vm.openNewSchedule}})]:_vm._e()],2),(_vm.type_schedule === 1)?[_c('div',{key:'REFRESH_SCHEDULE_SHIFT_' + _vm.refresh,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefShift",attrs:{"datablock":{
                                    id: 'data_select_shift',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_shift') + ':',
                                    text: _vm.$t('shift'),
                                    label: _vm.$t('shift'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: false
                                },"datainput":{
                                    id: 'value_data_select_shift',
                                    name: 'data[shift][]'
                                },"selected_input":_vm.shift,"feed":_vm.getterFeed['shift']},on:{"accept":function($event){return _vm.checkSelector('RefShift')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('shift') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.shift).join(', ')))])]],2)]:(_vm.type_schedule === 2)?[_c('div',{key:'schedule_' + _vm.refreshSchedule,staticClass:"gh-row-content"},[_c('Schedule',{attrs:{"scheduleData":_vm.getterCasuistry['schedule'] !== undefined ? _vm.getterCasuistry['schedule'] : [],"view":true}})],1)]:_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefSpecialty",attrs:{"datablock":{
                                id: 'data_select_department_specialty',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_department_specialty') + ':',
                                text: _vm.$t('department_specialty'),
                                label: _vm.$t('department_specialty'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            },"datainput":{
                                id: 'value_data_select_department_specialty',
                                name: 'data[department_specialty][]'
                            },"selected_input":_vm.department_specialty,"feed":_vm.getterFeed['department-specialty']},on:{"accept":function($event){return _vm.checkSelector('RefSpecialty')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('department_specialty') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.department_specialty).join(', ')))])]],2),(_vm.$root._route.params.id !== undefined && !_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefTraining",attrs:{"datablock":{
                            id: 'data_select_training',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_training') + ':',
                            text: _vm.$t('training'),
                            label: _vm.$t('training'),
                            type: 'checkbox',
                            columns: 3,
                            style: 'display: none;',
                            required: false
                        },"datainput":{
                            id: 'value_data_select_training',
                            name: 'data[training][]'
                        },"selected_input":_vm.selected_training,"disabled_input":_vm.disabled_training,"feed":_vm.feed_training},on:{"accept":function($event){return _vm.checkSelector('RefTraining')}}})]:_vm._e()],2),(_vm.$root._route.params.id !== undefined || (_vm.getterCasuistry !== undefined && _vm.getterCasuistry['training'] && _vm.getterCasuistry['training'].length !== 0))?[_c('GhTableCommon',{attrs:{"extratable":{
                        id: 'training'
                    },"header":_vm.header,"data":_vm.$root._route.params.id !== undefined ? _vm.training : _vm.getterCasuistry['training']},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.$root._route.params.id !== undefined && !_vm.$root._route.params.view)?[_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                        id: 'btn_modify_training',
                                        text: _vm.$t('modify')
                                    }},on:{"click":function($event){return _vm.openModify()}}})],1)]:_vm._e()]},proxy:true}],null,false,3669446142)})]:_vm._e()],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }