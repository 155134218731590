<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <DropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
                <component :is="components.general" :html="dataPopup.html" :title="dataPopup.title" :type="dataPopup.type" :stylePopup="dataPopup.stylePopup" :subTitle="dataPopup.subTitle" :bodyText="dataPopup.bodyText" :showButton="dataPopup.showButton" @accept="dataPopup.allowEmit ? doEmmit(dataPopup.typeEmit) : ''">
                    <template v-slot:content>
                        <template v-if="dataPopup.showSlot">
                            <form id="form_popup">
                                <template v-if="dataPopup.from === 'disable'">
                                    <div class="gh-content-global" style="padding-bottom: unset !important;">
                                        <div class="gh-row-content">
                                            <GhCalendar
                                                :datalabel="{
                                                    id: 'label_data_start_date',
                                                    text: $t('start_date'),
                                                    style: 'width: 100px; min-width: 100px; text-align: right; margin-top: 2px;'
                                                }"
                                                :datainput="{
                                                    id: 'data_date_start_date',
                                                    name: 'data[start_date]',
                                                    value: '',
                                                    disabled_date: 'before',
                                                    range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                                    required: true
                                                }"
                                            />
                                            <GhCalendar
                                                :datalabel="{
                                                    id: 'label_data_end_date',
                                                    text: $t('end_date'),
                                                    style: 'width: 100px; min-width: 100px; text-align: right; margin-top: 2px;'
                                                }"
                                                :datainput="{
                                                    id: 'data_date_end_date',
                                                    name: 'data[end_date]',
                                                    value: '',
                                                    disabled_date: 'before',
                                                    range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                                    required: true
                                                }"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </form>
                        </template>
                    </template>
                </component>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import DropSections from "@/view/Shared/Sections/DropSections.vue";
    import GhCalendar from "fe-gh-input-calendar-lib";

    import {mapGetters} from "vuex";

    import location_type from './LocationType/main.vue';
    import location from './Location/main.vue'

    import general from "@/view/Shared/Popups/general.vue";

    export default {
        name: 'configuration_location',
        components: {
            DropSections,
            LoaderTemplate,
            GhCalendar
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'location_type', component: location_type, result: 'location_type', second_text: 'results'},
                    {id: 2, name: 'location', component: location, result: 'location', second_text: 'results'}
                ],
                components: {
                    general: general
                },
                dataPopup: {
                    id: null,
                    type: '',
                    title: '',
                    stylePopup: '',
                    subTitle: '',
                    bodyText: '',
                    showButton: false,
                    showSlot: false,
                    allowEmit: false,
                    typeEmit: '',
                    html: false
                },
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'location'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'location'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'location'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        },
        methods:{
            async doEmmit(type){
                switch (type){
                    case 'disable':
                        if(this.Global.checkRequired('form_popup') === 'OK'){
                            const formData = new FormData(document.getElementById('form_popup'));
                            this.$store.dispatch('setByType', {
                                formData: [...formData],
                                id: this.dataPopup.id,
                                from: 'location',
                                type: 'location',
                                extra_url: 'disable',
                            }, {root: true}).then(async (response) => {
                                if(response !== undefined && response.status === 200){
                                    await this.$store.dispatch('getPermissionByType', {type: 'location'}, {root: true});
                                    await this.$store.dispatch('getCountersByType', {type: 'location'}, {root: true});
                                    await this.$store.dispatch('getListByType', {
                                        from: 'location',
                                        type: 'location'
                                    }, {root: true});
                                    this.Global.openPopUp('popup_general');
                                }
                            });
                        }
                        break;
                }
            }
        }
    }
</script>