<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            read_only: $root._route.params.id !== undefined,
                            value: code
                        }"
                        :name="{
                            show: true,
                            required: true,
                            read_only: $root._route.params.id !== undefined,
                            other_name: 'diagnosis',
                            value: diagnosis
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_department_specialty',
                                    class: 'T15_b',
                                    subtitle: $t('select_department_specialty') + ':',
                                    text: $t('department_specialty') + '*',
                                    label: $t('department_specialty'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_department_specialty',
                                    name: 'data[department_specialty]'
                                }"
                                :selected_input="department_specialty"
                                :feed="getterFeed['department-specialty']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('department_specialty') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(department_specialty).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_procedure_category',
                                    class: 'T15_b',
                                    subtitle: $t('select_procedure_category') + ':',
                                    text: $t('procedure_category') + '*',
                                    label: $t('procedure_category'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_procedure_category',
                                    name: 'data[procedure_category]'
                                }"
                                :selected_input="procedure_category"
                                :feed="getterFeed['procedure-category']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure_category') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(procedure_category).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_procedure_subcategory',
                                    class: 'T15_b',
                                    subtitle: $t('select_procedure_subcategory') + ':',
                                    text: $t('procedure_subcategory') + '*',
                                    label: $t('procedure_subcategory'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_procedure_subcategory',
                                    name: 'data[procedure_subcategory]'
                                }"
                                :selected_input="procedure_subcategory"
                                :feed="getterFeed['procedure-subcategory']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure_subcategory') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(procedure_subcategory).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="$root._route.params.view">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('w2_priority') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" >{{Object.values(w2_priority).join(', ')}}</label>
                        </template>
                        <template v-else>
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_w2_priority',
                                    class: 'T15_b',
                                    subtitle: $t('select_w2_priority') + ':',
                                    text: $t('w2_priority') + '*',
                                    label: $t('w2_priority'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_w2_priority',
                                    name: 'data[w2_priority]'
                                }"
                                :selected_input="w2_priority"
                                :feed="getterFeed['w2-priority']"
                            />
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_pcats_code',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhDataSelector
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed', 'getterCasuistry'])
        },
        data(){
            return {
                code: '',
                diagnosis: '',
                department_specialty: [],
                procedure_category: [],
                procedure_subcategory: [],
                w2_priority: [],
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view) {
                await this.$store.dispatch('destroyFeed', {
                    from: 'department-specialty'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'procedure-category'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'procedure-subcategory'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'w2-priority'
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'procedure'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'department-specialty'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'procedure-category'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'procedure-subcategory'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'w2-priority'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'procedure',
                    type: 'pcats-code',
                    id: this.$root._route.params.id
                }, {root: true});


                this.code = this.getterGet.procedure['pcats-code'].code;
                this.diagnosis = this.getterGet.procedure['pcats-code'].diagnosis;
                this.department_specialty = this.getterGet.procedure['pcats-code'].department_specialty;
                this.procedure_category = this.getterGet.procedure['pcats-code'].procedure_category;
                this.procedure_subcategory = this.getterGet.procedure['pcats-code'].procedure_subcategory;
                this.w2_priority = this.getterGet.procedure['pcats-code'].w2_priority;
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>

<style>
    #data_select_department_specialty_selector_label, #data_select_procedure_category_selector_label, #data_select_procedure_subcategory_selector_label, #data_select_w2_priority_selector_label {
        width: 150px;
        text-align: right;
    }
</style>