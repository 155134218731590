import General from '@/view/Configuration/General/main.vue';

import Shift from './Shift/router';
import ConstraintCategory from './ConstraintCategory/router';
import ConstraintType from './ConstraintType/router';
import Gender from './Gender/router';
import Relation from './Relation/router';

const route = [
    {
        path: '/configuration/general',
        name: 'config_general',
        props: {showMenu: true, showUser: true},
        component: General
    }
];

const routes = [
    ...route,
    ...Shift,
    ...ConstraintCategory,
    ...ConstraintType,
    ...Gender,
    ...Relation
];

export default routes;