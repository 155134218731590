var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DropSections',{attrs:{"sections":_vm.sections,"result":_vm.getterCounters,"perm":_vm.getterPermission}}),_c(_vm.components.general,{tag:"component",attrs:{"html":_vm.dataPopup.html,"title":_vm.dataPopup.title,"type":_vm.dataPopup.type,"stylePopup":_vm.dataPopup.stylePopup,"subTitle":_vm.dataPopup.subTitle,"bodyText":_vm.dataPopup.bodyText,"showButton":_vm.dataPopup.showButton},on:{"accept":function($event){_vm.dataPopup.allowEmit ? _vm.doEmmit(_vm.dataPopup.typeEmit) : ''}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.dataPopup.showSlot)?[_c('form',{attrs:{"id":"form_popup"}},[(_vm.dataPopup.from === 'disable')?[_c('div',{staticClass:"gh-content-global",staticStyle:{"padding-bottom":"unset !important"}},[_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                                                id: 'label_data_start_date',
                                                text: _vm.$t('start_date'),
                                                style: 'width: 100px; min-width: 100px; text-align: right; margin-top: 2px;'
                                            },"datainput":{
                                                id: 'data_date_start_date',
                                                name: 'data[start_date]',
                                                value: '',
                                                disabled_date: 'before',
                                                range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                                required: true
                                            }}}),_c('GhCalendar',{attrs:{"datalabel":{
                                                id: 'label_data_end_date',
                                                text: _vm.$t('end_date'),
                                                style: 'width: 100px; min-width: 100px; text-align: right; margin-top: 2px;'
                                            },"datainput":{
                                                id: 'data_date_end_date',
                                                name: 'data[end_date]',
                                                value: '',
                                                disabled_date: 'before',
                                                range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                                required: true
                                            }}})],1)])]:_vm._e()],2)]:_vm._e()]},proxy:true}])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }