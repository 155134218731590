<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: getterGet.staff !== undefined && getterGet.staff['staff-type'] !== undefined ? getterGet.staff['staff-type'].code : ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.staff !== undefined && getterGet.staff['staff-type'] !== undefined ? getterGet.staff['staff-type'].name : ''
                        }"
                        :description="{
                            show: true,
                            required: false,
                            value: getterGet.staff !== undefined && getterGet.staff['staff-type'] !== undefined ? getterGet.staff['staff-type'].description : ''
                        }"
                    />
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'form_staff_type',
        components: {
            LoaderTemplate,
            CodeNameDescription
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'staff'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'staff',
                    type: 'staff-type',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>