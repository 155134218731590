var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                id: 'location'
            },"header":_vm.header,"data":_vm.getterList.location !== undefined && _vm.getterList.location['location'] !== undefined ? _vm.getterList.location['location'].data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList.location['location'].actions.view)?[_c('router-link',{attrs:{"to":{name: 'config_location_location_view', params: {id: itemProp.id, view: true}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_view_location_' + itemProp.id,
                                text: _vm.$t('view'),
                                icon: require('@/assets/gh_new_dessign/view.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.location['location'].actions.modify)?[_c('router-link',{attrs:{"to":{name: 'config_location_location_modify', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_edit_location_' + itemProp.id,
                                text: _vm.$t('modify'),
                                icon: require('@/assets/gh_new_dessign/edit.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.location['location'].actions.disable && itemProp.disable)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_disable_location_' + itemProp.id,
                            text: _vm.$t('disable'),
                            icon: require('@/assets/gh_new_dessign/denied.svg')
                        }},on:{"click":function($event){return _vm.doAction('disable', itemProp)}}})]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterList.location['location'].actions.add)?[_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'config_location_location'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_new_location',
                                    text: _vm.$t('add')
                                }}})],1)],1)]:_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }