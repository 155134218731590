import TrainingType from '@/view/Configuration/Training/TrainingType/Template/section.vue';

const route = [
    {
        path: '/configuration/training/training-type',
        name: 'config_training_training_type',
        props: {showMenu: true, showUser: true},
        component: TrainingType,
        children: [
            {
                path: '/configuration/training/training-type/:id',
                name: 'config_training_training_type_modify',
                component: TrainingType,
                props: {id: null}
            },
            {
                path: '/configuration/training/training-type/:id/:view',
                name: 'config_training_training_type_view',
                component: TrainingType,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;