import RegeneratePassword from '../../view/PasswordRegenerate/main.vue';

const routes = [
    {
        path: '/reset/rmbpwd/:token',
        name: 'rmbpwd',
        props: {showMenu: false, token: null},
        component: RegeneratePassword
    }
];

export default routes;