var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.code
                    },"name":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        other_name: 'diagnosis',
                        value: _vm.diagnosis
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_department_specialty',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_department_specialty') + ':',
                                text: _vm.$t('department_specialty') + '*',
                                label: _vm.$t('department_specialty'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_department_specialty',
                                name: 'data[department_specialty]'
                            },"selected_input":_vm.department_specialty,"feed":_vm.getterFeed['department-specialty']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('department_specialty') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.department_specialty).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_procedure_category',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_procedure_category') + ':',
                                text: _vm.$t('procedure_category') + '*',
                                label: _vm.$t('procedure_category'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_procedure_category',
                                name: 'data[procedure_category]'
                            },"selected_input":_vm.procedure_category,"feed":_vm.getterFeed['procedure-category']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('procedure_category') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.procedure_category).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_procedure_subcategory',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_procedure_subcategory') + ':',
                                text: _vm.$t('procedure_subcategory') + '*',
                                label: _vm.$t('procedure_subcategory'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_procedure_subcategory',
                                name: 'data[procedure_subcategory]'
                            },"selected_input":_vm.procedure_subcategory,"feed":_vm.getterFeed['procedure-subcategory']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('procedure_subcategory') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.procedure_subcategory).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content"},[(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('w2_priority') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.w2_priority).join(', ')))])]:[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_w2_priority',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_w2_priority') + ':',
                                text: _vm.$t('w2_priority') + '*',
                                label: _vm.$t('w2_priority'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_w2_priority',
                                name: 'data[w2_priority]'
                            },"selected_input":_vm.w2_priority,"feed":_vm.getterFeed['w2-priority']}})]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }