import StaffType from '@/view/Configuration/Staff/StaffType/Template/section.vue';

const route = [
    {
        path: '/configuration/staff/staff-type',
        name: 'config_staff_staff_type',
        props: {showMenu: true, showUser: true},
        component: StaffType,
        children: [
            {
                path: '/configuration/staff/staff-type/:id',
                name: 'config_staff_staff_type_modify',
                component: StaffType,
                props: {id: null}
            },
            {
                path: '/configuration/staff/staff-type/:id/:view',
                name: 'config_staff_staff_type_view',
                component: StaffType,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;