import EquipmentType from '@/view/Configuration/Equipment/EquipmentType/Template/section.vue';

const route = [
    {
        path: '/configuration/equipment/equipment-type',
        name: 'config_equipment_equipment_type',
        props: {showMenu: true, showUser: true},
        component: EquipmentType,
        children: [
            {
                path: '/configuration/equipment/equipment-type/:id',
                name: 'config_equipment_equipment_type_modify',
                component: EquipmentType,
                props: {id: null}
            },
            {
                path: '/configuration/equipment/equipment-type/:id/:view',
                name: 'config_equipment_equipment_type_view',
                component: EquipmentType,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;