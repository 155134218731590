<template>
    <div v-show="false" id="remember_password" class="modal-backdrop">
        <div class="modal fondo_dropdown_2_" role="dialog" style="width: 440px; height: auto; overflow: hidden; position: fixed">
            <div class="recordarnombre_t11 t11 text-inline">{{$t('text_remember_password')}}</div>
            <div class="introduzcalosdatos_t12 t12" style="padding-top: 10px; padding-bottom: 20px;">{{$t('extra_text_remember_password')}}</div>
            <div style="width: 313px; margin-left: 24px; margin-top: 20px; padding-top: 20px;">
                <GhInputLogin
                    :datalabel="{
                        id: 'label_nif',
                        text: $t('id')
                    }"
                    :datainput="{
                        id: 'input_nif',
                        type: 'text',
                        name: 'id',
                        class: 'username',
                        error: '',
                        show_message: true
                    }"
                    ref="GhInputCif"
                />
            </div>
            <div style="width: 313px; margin-left: 24px; margin-top: 20px;">
                <GhInputLogin
                    :datalabel="{
                        id: 'label_email',
                        text: $t('email')
                    }"
                    :datainput="{
                        id: 'input_email',
                        type: 'text',
                        name: 'email',
                        class: 'email',
                        error: '',
                        show_message: true
                    }"
                    ref="GhInputEmail"
                />
            </div>
            <div style="margin-top: 30px; margin-bottom: 15px; flex-flow: row-reverse; display: flex;">
                <GhAction
                    :dataction="{
                        id: 'close_remember_password',
                        text: $t('close'),
                        class: 't13 alink'
                    }"
                    @click="Global.closePopUp('remember_password')"
                />
                <GhAction
                    :dataction="{
                        id: 'push_remember_password',
                        text: $t('remember_password'),
                        class: 't13 alink'
                    }"
                    @click="doClickRememberPwd()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhInputLogin from "fe-gh-input-login-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'popup_remember_password',
        components: {
            GhInputLogin,
            GhAction
        },
        methods: {
            async doClickRememberPwd(){
                await this.$store.dispatch('goRememberPassword',{
                    cif: this.$refs.GhInputCif.$el.children[1].children[0].value,
                    email: this.$refs.GhInputEmail.$el.children[1].children[0].value
                },{root:true});
            }
        }
    }
</script>