var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department-specialty'] !== undefined ? _vm.getterGet.department['department-specialty'].code : ''
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department-specialty'] !== undefined ? _vm.getterGet.department['department-specialty'].name : ''
                    },"description":{
                        show: true,
                        required: false,
                        value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department-specialty'] !== undefined ? _vm.getterGet.department['department-specialty'].description : ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'data_select_staff',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_staff') + ':',
                            text: _vm.$t('staff') + '*',
                            label: _vm.$t('staff'),
                            type: 'checkbox',
                            columns: 3,
                            style: '',
                            required: true
                        },"datainput":{
                            id: 'value_data_select_staff',
                            name: 'data[staff][]'
                        },"selected_input":_vm.getterGet.department !== undefined && _vm.getterGet.department['department-specialty'] !== undefined ? _vm.getterGet.department['department-specialty'].staff : [],"feed":_vm.getterFeed['staff']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('staff') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.department !== undefined && _vm.getterGet.department['department-specialty'] !== undefined ? Object.values(_vm.getterGet.department['department-specialty'].staff).join(', ') : ''))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }