<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <GhTableCommon
                :extratable="{
                    id: 'staff'
                }"
                :header="header"
                :data="getterList.staff !== undefined && getterList.staff['staff'] !== undefined ? getterList.staff['staff'].data : []"
            >
                <template v-slot:actions="{itemProp}">
                    <template v-if="getterList.staff['staff'].actions.view">
                        <router-link :to="{name: 'config_staff_staff_view', params: {id: itemProp.id, view: true}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_view_staff_' + itemProp.id,
                                    text: $t('view'),
                                    icon: require('@/assets/gh_new_dessign/view.svg')
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-if="getterList.staff['staff'].actions.modify">
                        <router-link :to="{name: 'config_staff_staff_modify', params: {id: itemProp.id}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_edit_staff_' + itemProp.id,
                                    text: $t('modify'),
                                    icon: require('@/assets/gh_new_dessign/edit.svg')
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-if="getterList.staff['staff'].actions.sick_leave && itemProp.sick_leave">
                        <GhAction
                            :dataction="{
                                id: 'action_sick_leave_staff_' + itemProp.id,
                                text: $t('sick_leave'),
                                icon: require('@/assets/gh_new_dessign/return.svg')
                            }"
                            @click="doAction('sick_leave', itemProp)"
                        />
                    </template>
                </template>
                <template v-slot:footer>
                    <template v-if="getterList.staff['staff'].actions.add">
                        <div class="centered">
                            <router-link :to="{name: 'config_staff_staff'}">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_new_staff',
                                        text: $t('add')
                                    }"
                                />
                            </router-link>
                        </div>
                    </template>
                </template>
            </GhTableCommon>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhTableCommon from 'fe-gh-table-common-lib';

    import {mapGetters} from "vuex";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'list_staff',
        components: {
            GhAction, GhButton,
            LoaderTemplate,
            GhTableCommon
        },
        computed: {
            ...mapGetters(['getterList'])
        },
        data(){
            return {
                header: [
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'staff_type', field: 'staff_type', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'schedule', field: 'schedule', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 30%;'},
                    {text: 'department_specialty', field: 'department_specialty', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'training', field: 'training', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListByType', {
                from: 'staff',
                type: 'staff'
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        },
        methods: {
            async doAction(from, item){
                switch (from){
                    case 'sick_leave':
                        var html = false;
                        var bodyText = '';
                        if(item.planned_current.length !== 0){
                            html = true;
                            item.planned_current.forEach((i) => {
                                bodyText += '<div class="gh-row-content" style="padding-left: 20px; padding-right: 20px;"><label class="gh_text_field-label T15_b">' + i + '</label></div>';
                            });
                        }

                        this.$parent.$parent.$parent.$parent.dataPopup = {
                            id: item.id,
                            from: from,
                            type: 'warning',
                            title: from,
                            stylePopup: 'width: 650px, min-width: 650px, max-width: 650px,',
                            subTitle: 'next_current_active_plan_staff',
                            bodyText: bodyText,
                            showSlot: true,
                            showButton: true,
                            allowEmit: true,
                            typeEmit: from,
                            html: html
                        };

                        this.Global.openPopUp('popup_general');
                        break;
                }
            }
        }
    }
</script>