<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <DropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import DropSections from '@/view/Shared/Sections/DropSections.vue';

    import {mapGetters} from "vuex";

    import department_specialty from './DepartmentSpecialty/main.vue';
    import department from './Department/main.vue';

    export default {
        name: 'configuration_department',
        components: {
            LoaderTemplate,
            DropSections
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'department_specialty', component: department_specialty, result: 'department_specialty', second_text: 'results'},
                    {id: 2, name: 'department', component: department, result: 'department', second_text: 'results'},
                ],
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'department'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'department'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'department'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>