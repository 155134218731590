import Location from '@/view/Configuration/Location/main.vue';

import LocationView from './Location/router';
import LocationType from './LocationType/router';

const route = [
    {
        path: '/configuration/location',
        name: 'config_location',
        props: {showMenu: true, showUser: true},
        component: Location
    }
];

const routes = [
    ...route,
    ...LocationType,
    ...LocationView
];

export default routes;